import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../layout/Layout'
import store from '@/store'
import VueCookies from 'vue-cookies'

Vue.use(VueRouter)
Vue.use(VueCookies)

const routes = [
  /*
   **  rota padrão ou rota inicial
   */
  {
    path: '',
    redirect: '/',
    component: () => import('../views/web'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('../views/web/HomePage'),
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('../views/web/AboutPage'),
      },
      {
        path: '/print',
        name: 'print-page',
        component: () => import('../views/web/PrintPage'),
      },
      {
        path: '/print/:id',
        name: 'print-view',
        component: () => import('../views/web/PrintViewPage'),
      },
      {
        path: '/products_by_category/:category_name',
        name: 'siteProductsByCategories',
        component: () => import('../views/web/ProductsByCategory'),
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('../views/web/ContactPage'),
      },
      {
        path: '/products',
        name: 'products-page',
        component: () => import('../views/web/ProductsPage'),
      },
      {
        path: '/products/:id',
        name: 'products-view',
        component: () => import('../views/web/ProductsViewPage'),
      },
      {
        path: '/budget/cart',
        name: 'budget-cart',
        component: () => import('../views/web/BudgetCartPage'),
      },
      {
        path: '/profile',
        name: 'my-profile',
        component: () => import('../views/web/MyProfile'),
      },
    ],
  },

  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/admin'),
  },

  {
    path: '/admin/settings',
    name: 'settings',
    component: () => import('../views/settings/GlobalSettings.vue'),
  },

  /*
   **  rota de estoque
   */
  {
    path: '/stock',
    redirect: '/stock/dashboard',
    component: Layout,
    children: [
      {
        path: 'dashboard',
        name: 'stockDashboard',
        component: () => import('../views/stock/Dashboard'),
      },
      {
        path: 'products',
        name: 'products',
        component: () => import('../views/stock/Products'),
      },
      {
        path: 'products/new',
        name: 'products-new',
        component: () => import('@/components/stock/products/ProductsNew'),
      },
      {
        path: 'products/:id',
        name: 'products-edit',
        component: () => import('@/components/stock/products/ProductsEdit'),
      },
      {
        path: 'vendors',
        name: 'vendors',
        component: () => import('../views/stock/Vendors'),
      },
      {
        path: 'vendors/new',
        name: 'vendors-new',
        component: () => import('@/components/stock/vendors/VendorsNew'),
      },
      {
        path: 'vendors/:id',
        name: 'vendors-edit',
        component: () => import('@/components/stock/vendors/VendorsEdit'),
      },
      {
        path: 'categories',
        name: 'categories',
        component: () => import('../views/stock/Categories'),
        children: [
          {
            path: 'new',
            name: 'categories-new',
            component: () =>
              import('@/components/stock/categories/CategoriesNew'),
          },
          {
            path: ':id',
            name: 'categories-edit',
            component: () =>
              import('@/components/stock/categories/CategoriesEdit'),
          },
        ],
      },
      {
        path: 'ncm',
        name: 'ncm',
        component: () => import('../views/stock/Ncm'),
      },
      {
        path: 'ncm/new',
        name: 'ncm-new',
        component: () => import('@/components/stock/ncm/NcmNew'),
      },
      {
        path: 'ncm/:id',
        name: 'ncm-edit',
        component: () => import('@/components/stock/ncm/NcmEdit'),
      },
      {
        path: 'colors',
        name: 'colors',
        component: () => import('../views/stock/Colors'),
      },
      {
        path: 'colors/new',
        name: 'colors-new',
        component: () => import('@/components/stock/colors/ColorsNew'),
      },
      {
        path: 'colors/:id',
        name: 'colors-edit',
        component: () => import('@/components/stock/colors/ColorsEdit'),
      },
      {
        path: 'print',
        name: 'print',
        component: () => import('../views/stock/Print'),
      },
      {
        path: 'print/new',
        name: 'print-new',
        component: () => import('@/components/stock/print/PrintNew'),
      },
      {
        path: 'print/:id',
        name: 'print-edit',
        component: () => import('@/components/stock/print/PrintEdit'),
      },
      {
        path: 'composition',
        name: 'composition',
        component: () => import('../views/stock/Composition'),
      },
      {
        path: 'composition/new',
        name: 'composition-new',
        component: () =>
          import('@/components/stock/composition/CompositionNew'),
      },
      {
        path: 'composition/:id',
        name: 'composition-edit',
        component: () =>
          import('@/components/stock/composition/CompositionEdit'),
      },
      {
        path: 'factor',
        name: 'factor',
        component: () => import('../views/stock/Factor'),
      },
      {
        path: 'factor/new',
        name: 'factor-new',
        component: () => import('@/components/stock/factor/FactorNew'),
      },
      {
        path: 'factor/:id',
        name: 'factor-edit',
        component: () => import('@/components/stock/factor/FactorEdit'),
      },
    ],
  },

  /*
   **  rota de pessoas
   */
  {
    path: '/people',
    redirect: '/people/dashboard',
    component: Layout,
    children: [
      {
        path: 'dashboard',
        name: 'people-dashboard',
        component: () => import('../views/people/Dashboard'),
      },
      {
        path: 'users',
        name: 'users',
        component: () => import('../views/people/Users'),
      },
      {
        path: 'users/new',
        name: 'users-new',
        component: () => import('../components/people/users/UsersNew'),
      },
      {
        path: 'users/:id',
        name: 'users-edit',
        component: () => import('../components/people/users/UsersEdit'),
      },
      {
        path: 'clients',
        name: 'clients',
        component: () => import('../views/people/Clients'),
      },
      {
        path: 'clients/new',
        name: 'clients-new',
        component: () => import('../components/people/clients/ClientsNew'),
      },
      {
        path: 'clients/:id',
        name: 'clients-edit',
        component: () => import('../components/people/clients/ClientsEdit'),
      },
      {
        path: 'search-clients',
        name: 'search-clients',
        component: () => import('../views/people/SearchClients'),
      },
      {
        path: 'sellers',
        name: 'sellers',
        component: () => import('../views/people/Sellers'),
      },
      {
        path: 'sellers/new',
        name: 'sellers-new',
        component: () => import('../components/people/sellers/SellersNew'),
      },
      {
        path: 'sellers/:id',
        name: 'sellers-edit',
        component: () => import('../components/people/sellers/SellersEdit'),
      },
      {
        path: 'agencies',
        name: 'agencies',
        component: () => import('../views/people/Agencies'),
      },
      {
        path: 'agencies/new',
        name: 'agencies-new',
        component: () => import('../components/people/agencies/AgenciesNew'),
      },
      {
        path: 'agencies/:id',
        name: 'agencies-edit',
        component: () => import('../components/people/agencies/AgenciesEdit'),
      },
      {
        path: 'companies',
        name: 'companies',
        component: () => import('../views/people/Companies'),
      },
      {
        path: 'companies/new',
        name: 'companies-new',
        component: () => import('../components/people/companies/CompaniesNew'),
      },
      {
        path: 'companies/:id',
        name: 'companies-edit',
        component: () => import('../components/people/companies/CompaniesEdit'),
      },
      // {
      //   path: 'profiles',
      //   name: 'profiles',
      //   component: () => import('../views/people/Profiles'),
      // },
      // {
      //   path: 'profiles/new',
      //   name: 'profiles-new',
      //   component: () => import('../components/people/profiles/ProfilesNew'),
      // },
      // {
      //   path: 'profiles/:id',
      //   name: 'profiles-edit',
      //   component: () => import('../components/people/profiles/ProfilesEdit'),
      // },
    ],
  },

  /*
   **  rota de comercial
   */
  {
    path: '/comercial',
    redirect: '/comercial/dashboard',
    component: Layout,
    children: [
      {
        path: 'dashboard',
        name: 'comercial-dashboard',
        component: () => import('../views/comercial/Dashboard'),
      },
      {
        path: 'budgets',
        name: 'budgets',
        component: () => import('../views/comercial/Budgets'),
      },
      {
        path: 'budgets/new',
        name: 'budgets-new',
        component: () => import('../components/comercial/budgets/BudgetsNew'),
      },
      {
        path: 'budgets/:id',
        name: 'budgets-edit',
        component: () => import('../components/comercial/budgets/BudgetsEdit'),
      },
      {
        path: 'orders',
        name: 'orders',
        component: () => import('../views/comercial/Orders'),
      },
      {
        path: 'orders/new',
        name: 'orders-new',
        component: () => import('../components/comercial/orders/OrdersNew'),
      },
      {
        path: 'orders/:id',
        name: 'orders-edit',
        component: () => import('../components/comercial/orders/OrdersEdit'),
      },
      {
        path: 'order-products',
        name: 'order-products',
        component: () => import('../views/comercial/OrderProducts'),
      },
      {
        path: 'crm',
        name: 'crm',
        component: () => import('../views/comercial/Crm'),
        children: [
          {
            path: ':id',
            name: 'crm-edit',
            component: () => import('../components/comercial/crm/CrmEdit'),
          },
        ],
      },
      {
        path: 'crm/new',
        name: 'crm-new',
        component: () => import('../components/comercial/crm/CrmNew'),
      },
      {
        path: 'products',
        name: 'comercial-products',
        component: () => import('../views/comercial/Products'),
      },
    ],
  },

  /*
   **  rota de site
   */
  {
    path: '/site',
    redirect: '/site/banners',
    component: Layout,
    children: [
      {
        path: 'banners',
        name: 'siteBanners',
        component: () => import('../views/site/Banners'),
      },
      {
        path: 'products',
        name: 'siteProducts',
        component: () => import('../views/site/Products'),
      },
      {
        path: 'prints',
        name: 'sitePrints',
        component: () => import('../views/site/Prints'),
      },
      {
        path: 'contacts',
        name: 'siteContacts',
        component: () => import('../views/site/Contacts'),
      },
      {
        path: 'faq',
        name: 'siteFaq',
        component: () => import('../views/site/FAQ'),
      },
      {
        path: 'email-news',
        name: 'siteEmailNews',
        component: () => import('../views/site/EmailsNews'),
      },
    ],
  },
]

const router = new VueRouter({
  ignoreDuplicates: true,
  mode: 'history',
  routes,
})

/*
 ** Impede a navegação entre a aplicação se o
 ** usuário não estiver autenticado
 */
router.afterEach((to, from) => {
  if (from.name !== null && from.name !== 'admin' && to.name === 'admin') {
    window.location.reload()
  }
})

router.beforeEach((to, from, next) => {
  if (to.name === 'my-profile' && !Vue.$cookies.isKey('isAuth')) {
    next('/')
  }
  if (
    from.name === 'admin' &&
    to.name !== 'admin' &&
    to.name !== 'home' &&
    !Vue.$cookies.isKey('isAuth')
  ) {
    store.commit('setCustomModal', {
      show: true,
      message: 'Você precisa estar logado',
    })
    next({ name: 'admin' })
  } else {
    store.commit('setModal', {
      show: false,
      component: '',
      text: '',
      caption: null,
    })
    store.commit('setFilterDrawer', {
      isOpened: false,
      component: '',
      filterCount: 0,
    })

    let blockedPaths = ['stock', 'people', 'site', 'comercial']
    let pathRoot = to.path.split('/')[1]

    if (
      blockedPaths.some((path) => path === pathRoot) &&
      !Vue.$cookies.isKey('isAuth')
    ) {
      next('/')
    } else if (
      blockedPaths.some((path) => path === pathRoot) &&
      Vue.$cookies.get('type') === 'client'
    ) {
      next('/')
    } else {
      next()
    }
  }
})

export default router
