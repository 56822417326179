import Vue from 'vue'

const checkPermission = (state) => {
  if (state.some((el) => el === Vue.$cookies.get('type'))) {
    return true
  } else {
    return false
  }
}

export default checkPermission
