import checkPermission from './CheckPermission'

export default {
  namespaced: true,
  state() {
    return {
      comercial_module: ['master', 'seller', 'editor', 'production'],
      crm: ['master', 'seller'],
      products_search: ['master', 'seller'],
      budgets: ['master', 'seller', 'editor'],
      orders: ['master', 'seller', 'editor'],
      order_company_data: ['master', 'editor'],
      order_payment_data: ['master', 'seller'],
      order_price_data: ['master', 'seller'],
      order_tabs: {
        invoicing: ['master', 'seller', 'editor'],
        obervations: ['master', 'seller', 'editor'],
      },
      dashboard: ['master', 'seller'],
      order_products: ['master', 'seller', 'production'],
      order_products_list_columns: {
        total_value: ['master'],
        see_art: ['master', 'production'],
      },
      order_products_steps: {
        buy: ['master', 'seller'],
        print: ['master', 'seller'],
        check: ['master', 'seller'],
        produce: ['master', 'production'],
        invoice: ['master'],
      },
      cancel: {
        orders: ['master'],
      },
      delete: {
        budgets: ['master'],
        orders: ['master'],
      },
      edit: {
        orders: ['master', 'seller'],
        budgets: ['master', 'seller'],
      },
      read: {
        order_tabs: {
          invoicing: ['master', 'seller']
        }
      },
      create: {
        budgets: ['master', 'seller'],
      },
    }
  },
  getters: {
    showModule(state) {
      return checkPermission(state.comercial_module)
    },
    showCrmSection(state) {
      return checkPermission(state.crm)
    },
    showProductsSearchSection(state) {
      return checkPermission(state.products_search)
    },
    showBudgetsSection(state) {
      return checkPermission(state.budgets)
    },
    showOrdersSection(state) {
      return checkPermission(state.orders)
    },
    showOrderProductsSection(state) {
      return checkPermission(state.order_products)
    },
    showDashboardSection(state) {
      return checkPermission(state.dashboard)
    },
    showOrderProductsBuyStep(state) {
      return checkPermission(state.order_products_steps.buy)
    },
    showOrderProductsPrintStep(state) {
      return checkPermission(state.order_products_steps.print)
    },
    showOrderProductsCheckStep(state) {
      return checkPermission(state.order_products_steps.check)
    },
    showOrderProductsProduceStep(state) {
      return checkPermission(state.order_products_steps.produce)
    },
    showOrderProductsInvoiceStep(state) {
      return checkPermission(state.order_products_steps.invoice)
    },
    showCancelOrder(state) {
      return checkPermission(state.cancel.orders)
    },
    showEditOrders(state) {
      return checkPermission(state.edit.orders)
    },
    showEditBudgets(state) {
      return checkPermission(state.edit.budgets)
    },
    showDeleteBudgets(state) {
      return checkPermission(state.delete.budgets)
    },
    showDeleteOrders(state) {
      return checkPermission(state.delete.orders)
    },
    showCreateBudgets(state) {
      return checkPermission(state.create.budgets)
    },
    showOrderProductsTotalValueColumn(state) {
      return checkPermission(state.order_products_list_columns.total_value)
    },
    showOrderProductsSeeArtColumn(state) {
      return checkPermission(state.order_products_list_columns.see_art)
    },
    showOrderCompanyData(state) {
      return checkPermission(state.order_company_data)
    },
    showOrderPaymentData(state) {
      return checkPermission(state.order_payment_data)
    },
    showOrderInvoicingTab(state) {
      return checkPermission(state.order_tabs.invoicing)
    },
    showOrderObservationsTab(state) {
      return checkPermission(state.order_tabs.obervations)
    },
    showOrderPriceData(state) {
      return checkPermission(state.order_price_data)
    },
    readOnlyOrderInvoicingTab(state) {
      return checkPermission(state.read.order_tabs.invoicing)
    },
  },
}
