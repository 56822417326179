import api from '@/provider/api'

export default {
  namespaced: true,
  state() {
    return {
      colors_selected: [],
      colorDrawner: {
        isOpened: false,
      },
    }
  },
  mutations: {
    SET_COLORS_SELECTED(state, colors) {
      state.colors_selected = colors
    },
    SET_COLOR_DRAWNER(state, actionOpen) {
      state.colorDrawner.isOpened = actionOpen.isOpened
    },
  },
  actions: {
    setColorsSelected({ commit }, colors) {
      commit('SET_COLORS_SELECTED', colors)
    },
    async groupColorsSelected(_, colors) {
      await api.post('/product_colors/group-colors', {
        ...colors,
      })
    },
    handleColorDrawner({ commit }, actionOpen) {
      commit('SET_COLOR_DRAWNER', actionOpen)
    },
  },
  getters: {
    getColorsSelected(state) {
      return state.colors_selected.map((e) => ({
        id: e.id,
        name: e.name,
      }))
    },
    colorDrawnerIsOpened(state) {
      return state.colorDrawner.isOpened
    },
  },
}
