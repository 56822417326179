import checkPermission from './CheckPermission'

export default {
  namespaced: true,
  state() {
    return {
      site_module: ['master', 'editor'],
      banners: ['master', 'editor'],
      contacts: ['master', 'editor'],
      faq: ['master', 'editor'],
      prints: ['master', 'editor'],
      newsletter: ['master', 'editor'],
      products: ['master', 'editor'],
    }
  },
  getters: {
    showModule(state) {
      return checkPermission(state.site_module)
    },
    showBannersSection(state) {
      return checkPermission(state.banners)
    },
    showContactsSection(state) {
      return checkPermission(state.contacts)
    },
    showFaqSection(state) {
      return checkPermission(state.faq)
    },
    showPrintsSection(state) {
      return checkPermission(state.prints)
    },
    showNewsletterSection(state) {
      return checkPermission(state.newsletter)
    },
    showProductsSection(state) {
      return checkPermission(state.products)
    },
  },
}
